import React, { useEffect, useState } from "react";
import "./Style.css";
import Logo from "../../assets/images/logo-dark.png";
import { Link, useLocation } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import axios from "axios";
import { AccountCircleOutlined } from "@mui/icons-material";
import getUserId from "../Functions/getUserId";

const Darkbar = () => {
  const location = useLocation();
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };

  const accountId = getQueryParams("account");
  const [balance, setBalance] = useState(0.0);
  const userId = getUserId();
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.post("${process.env.REACT_APP_API_URL}/api/accounts", {
          userId,
        });
        console.log("API Response:", response.data);
  
        const filteredAccount = response.data.find(
          (account) => String(account.account_key).trim() === String(accountId).trim()
        );
  
        console.log("Filtered Account:", filteredAccount);
        setAccountInfo(filteredAccount || null);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };
  
    if (accountId) {
      fetchAccounts();
    }
  }, [accountId]);
  


  return (
    <div className="darkbar">
      <nav className="navbar sticky-top navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand mx-3" to="/">
          <img src={Logo} width="70" alt="Logo" />
          </Link>
          <div className="d-flex justify-content-between align-items-center py-1">
            <div className="mx-2 text-light">
              <div className="text-light">
                Account:<span className="text-success mx-1">{accountInfo?.account_nickname || 0}</span>
              </div>
              <Link to="/wallets" className="text-light">
                <AccountBalanceWalletOutlinedIcon />
              </Link>
              <small className="fw-bold mx-1 text-light">
                {accountInfo?.account_balance || 0} USD
              </small>
            </div>
            <Link to="/dashboard" className="mx-2">
              <AccountCircleOutlined className="text-light" />
            </Link>
            <Link className="text-light fw-bold btn btn-theme btn-g mx-1 mt-0">
              Deposit
            </Link>
            <div className="mx-1">
              <LanguageIcon className="text-light" />
              <small className="mx-1 text-light">EN</small>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Darkbar;
