import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// Helper Routes
import AddToDemo from "./pages/Helper/AddToDemo";
import Deposit from "./pages/Wallet/Deposit";
import Withdraw from "./pages/Wallet/Withdraw";
import ConfirmDeposit from "./pages/Wallet/ConfirmDeposit";
import DepositPayment from "./pages/Wallet/DepositPayment";
import Terminal from "./pages/Terminal/Terminal";

// Lazy-load pages
const Home = lazy(() => import("./pages/Home/Home"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Newaccount = lazy(() => import("./pages/Dashboard/Newaccount"));
const CreateAccount = lazy(() => import("./pages/Dashboard/CreateAccount"));
const About = lazy(() => import("./pages/About/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Signup = lazy(() => import("./pages/Signup/Signup"));
const Signin = lazy(() => import("./pages/Signin/Signin"));
const NotFound = lazy(() => import("./pages/NotFound")); // 404 page

const App = () => {
  return (
    <Router>
      {/* ToastContainer for showing notifications */}
      <ToastContainer />
      {/* Suspense provides a fallback while components are loading */}
      <Suspense fallback={<div id="preloader"></div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/sign-in" element={<Signin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/confirm-deposit" element={<ConfirmDeposit />} />
          <Route path="/confirm-deposit/payment" element={<DepositPayment />} />
          <Route path="/terminal" element={<Terminal />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/new-account" element={<Newaccount />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/load-demo-account" element={<AddToDemo />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
