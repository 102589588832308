import React from 'react';
import './Style.css';
import Darkbar from './Darkbar';
import ChartWidget from './Widgets/ChartWidget';
import Timeline from './Widgets/TradingViewScreener';
import SingleSymbol from './Widgets/SingleSymbol';
import OrderPanel from './OrderPanel';

const Terminal = () => {
 return (<>
  <section className='layout container-fluid'>
  <Darkbar />
   <div className='row'>
    <div className='col-xl-3'>
     <div style={{ height: "200px" }}><SingleSymbol name="BTCUSD" /></div>
     <div style={{ height: "200px" }}><SingleSymbol name="AAPL" /></div>
     <div style={{ height: "200px" }}><SingleSymbol name="XAUUSD" /></div>
    </div>
    <div className='col-xl-6'><div style={{ height: "350px" }}><ChartWidget /></div><div style={{ height: "250px" }}><Timeline /></div></div>
    <div className='col-xl-3'><OrderPanel/></div>
   </div>
  </section>
 </>
 )
}

export default Terminal
