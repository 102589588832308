import React, { useState } from "react";
import "./Helper.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddToDemo = () => {
  const location = useLocation();
  const [balance, setBalance] = useState("");
  const [message, setMessage] = useState("");

  // Extract account ID from URL query params
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };

  const accountId = getQueryParams("id");

  // Get userId from JWT token
  const getUserIdFromToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken?.userId;
      } catch (error) {
        console.error("Invalid token:", error);
      }
    }
    return null;
  };

  const userId = getUserIdFromToken();

  // Handle input change for balance
  const handleBalanceChange = (e) => {
    setBalance(e.target.value);
  };

  const navigate = useNavigate();

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (balance === "" || isNaN(balance)) {
      setMessage("Please enter a valid balance.");
      return;
    }

    try {
      // Send a PUT request to update the balance
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/update-account-balance/${accountId}`,
        { account_balance: balance, userId: userId }
      );

      // Check if the update was successful
      if (response.status === 200) {
        toast.success("Balance updated successfully!");

        // Delay the redirect by 1 second to allow the toast to appear
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      } else {
        toast.error("Failed to update balance. Try again later.");
      }
    } catch (error) {
      console.error("Error updating balance:", error);
      setMessage("An error occurred while updating the balance.");
    }
  };

  return (
    <>
      <div className="blur-background"></div>
      <div
        className="modal show d-block"
        tabIndex="-1"
        style={{ display: "block", zIndex: 1050 }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <h3 className="fw-bold mx-2">Set Balance for Demo Account</h3>
            <div className="modal-body">
              <div>
                Account: <span className="fw-bold"> #{accountId}</span>
              </div>
              <div className="mt-1">
                <span>Amount:</span>
                <input
                  type="number"
                  className="form-control"
                  value={balance}
                  onChange={handleBalanceChange}
                />
              </div>
              <button
                className="btn btn-theme btn-y w-100 mt-2"
                onClick={handleSubmit}
              >
                Set Balance
              </button>
              {message && <p className="mt-2">{message}</p>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddToDemo;
