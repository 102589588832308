import React, { useEffect, useRef } from "react";

const SingleSymbol = ({ name }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Check if the container is available
    if (!containerRef.current) return;

    // Clear any existing content
    while (containerRef.current.firstChild) {
      containerRef.current.removeChild(containerRef.current.firstChild);
    }

    // Create the script element for the TradingView widget
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.async = true;
    script.type = "text/javascript";

    // Set the widget configuration using JSON.stringify for innerHTML
    script.innerHTML = JSON.stringify({
      symbol: name, // Dynamic symbol based on props
      width: "100%",
      height: "100%",
      locale: "en",
      dateRange: "12M",
      colorTheme: "dark",
      isTransparent: false,
      autosize: true,
      largeChartUrl: "",
    });

    // Append the script to the container ref
    containerRef.current.appendChild(script);

    // Cleanup function
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, [name]);

  return (
    <div
      className="tradingview-widget-container"
      ref={containerRef}
      style={{ height: "100%", width: "100%" }}
    >
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default SingleSymbol;
