import {jwtDecode} from "jwt-decode";

const getUserId = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decodedToken = jwtDecode(token); // Decode the token
      return decodedToken?.userId; // Return the userId from the decoded token
    } catch (error) {
      console.error("Invalid token:", error); // Catch and log any errors in decoding
    }
  }
  return null; // If no token exists or the decoding fails
};

export default getUserId;
