import React, { useEffect, useState } from "react";
import "./Style.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import Appbar from "../../components/Appbar/Appbar";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import getUserId from "../Functions/getUserId";

const ConfirmDeposit = () => {
  const location = useLocation();

  // Extract depositId (d_key) and accountId (account_key) from the URL query params
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };

  const accountId = getQueryParams("account"); // account key from URL
  const depositId = getQueryParams("deposit_method"); // deposit method key from URL

  const [depositMethods, setDepositMethods] = useState([]);
  const [selectedDeposit, setSelectedDeposit] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(accountId || ""); // Set selected accountId initially

  // Fetch deposit methods when the component mounts
  useEffect(() => {
    const fetchDepositMethods = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/deposit-methods`
        );
        const data = await response.json();
        setDepositMethods(data);

        // Set the selected deposit method if depositId exists in the URL
        if (depositId) {
          setSelectedDeposit(depositId);
        }
      } catch (error) {
        console.error("Error fetching deposit methods:", error);
      }
    };

    fetchDepositMethods();
  }, [depositId]);

  // Fetch accounts when the component mounts
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/accounts`,
          {
            userId: getUserId(), // Pass userId from the token
          }
        );
        setAccounts(response.data); // Set the accounts data to state
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchAccounts();
  }, []);

  return (
    <>
      <Sidebar />
      <Appbar />
      <section className="deposit container">
        <h2 className="fw-bold">Deposit</h2>
        <Link
          to={`/deposit?account=${accountId}`}
          style={{ fontSize: "15px" }}
          className="text-decoration-none"
        >
          See All Payment Methods
        </Link>

        {/* Deposit Methods Selection */}
        <div className="methods">
          <div className="row d-flex justify-content-between">
            <div className="col-xl-6">
              {/* Dropdown for selecting deposit method */}
              <div className="">
                <div style={{ fontSize: "12px" }} className="mt-3">
                  Payment Method
                </div>
                <select
                  id="depositSelect"
                  value={selectedDeposit} // Use selectedDeposit to manage the selected value
                  onChange={(e) => setSelectedDeposit(e.target.value)} // Update state on selection
                  className="form-control"
                >
                  {/* Only show the deposit method that matches d_key */}
                  <option value="">-- Select a Payment Method --</option>
                  {depositMethods.length > 0 ? (
                    depositMethods.map((method) => (
                      <option key={method.d_key} value={method.d_key}>
                        <img
                          src={method.d_logo}
                          alt={method.d_name}
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                        {method.d_name} ({method.d_network})
                      </option>
                    ))
                  ) : (
                    <option>No deposit method found</option>
                  )}
                </select>
              </div>

              {/* Dropdown for selecting account */}
              <div className="">
                <div style={{ fontSize: "12px" }} className="mt-3">
                  To Account
                </div>
                <select
                  id="accountSelect"
                  value={selectedAccount} // Set selected accountId from the URL or state
                  onChange={(e) => setSelectedAccount(e.target.value)} // Update state on account selection
                  className="form-control"
                >
                  {accounts.length > 0 ? (
                    accounts
                      .filter((account) => account.account_type === "Real")
                      .map((account) => (
                        <option
                          key={account.account_key}
                          value={account.account_key}
                        >
                          {account.account_nickname} ({account.account_balance}{" "}
                          USD)
                        </option>
                      ))
                  ) : (
                    <option>No accounts found</option>
                  )}
                </select>
              </div>
              <div className="mt-3">
                <p>
                  Deposit processing time depends on the blockchain transaction
                  fee you set when making a transfer.
                  <br />
                  Funds will be credited to the selected account in account
                  currency.
                </p>
              </div>

              {/* Submit Button */}
              <div className="d-flex align-items-end">
                <Link
                  to={`/confirm-deposit/payment?account=${accountId}&deposit_method=${depositId}`}
                  className="btn btn-theme btn-y  mt-2"
                >
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConfirmDeposit;
