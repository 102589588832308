import React, { useEffect, useRef } from "react";

const TradingViewScreener = () => {
  const containerRef = useRef();

  useEffect(() => {
    // Create the script element for the TradingView widget
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.async = true;
    script.type = "text/javascript";

    // Set the script content (widget configuration)
    script.innerHTML = JSON.stringify({
      width: "100%", // Set width to 100% to make it responsive
      height: "100%",
      defaultColumn: "overview",
      screener_type: "crypto_mkt", // Set type to crypto screener
      displayCurrency: "USD",
      colorTheme: "dark",
      locale: "en",
    });

    // Append the script to the container
    containerRef.current.appendChild(script);

    // Cleanup function to remove the script on unmount
    return () => {
      containerRef.current.innerHTML = "";
    };
  }, []);

  return (
    <div className="tradingview-widget-container" ref={containerRef} style={{ width: "200px", height: "100%" }}>
      <div  className="tradingview-widget-container__widget"></div>
    </div>
  );
};

export default TradingViewScreener;
