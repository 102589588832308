import React, { useState, useEffect } from "react";
import axios from "axios";

const OrderPanel = () => {
  const [selectedPair, setSelectedPair] = useState("BTC/USD");
  const [livePrice, setLivePrice] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [leverage, setLeverage] = useState(1);
  const [orderType, setOrderType] = useState("Market");

  // Handle the dynamic fetching of prices for crypto and forex
  const fetchPrice = async () => {
    try {
      let price;
      if (selectedPair.includes("USD")) {
        // Forex prices (Example: EUR/USD, GBP/USD)
        const forexRes = await axios.get(
          `https://www.freeforexapi.com/api/live?pairs=${selectedPair.replace("/", "")}`
        );
        console.log(forexRes); // Log the forex response for debugging
        price = forexRes.data.response.rates[selectedPair.replace("/", "")].rate;
      } else {
        // Crypto prices (Example: BTC/USD)
        const cryptoRes = await axios.get(
          `https://api.coingecko.com/api/v3/simple/price?ids=${selectedPair.split("/")[0].toLowerCase()}&vs_currencies=usd`
        );
        console.log(cryptoRes); // Log the crypto response for debugging
        price = cryptoRes.data[selectedPair.split("/")[0].toLowerCase()].usd;
      }
      setLivePrice(price);
    } catch (error) {
      console.error("Error fetching price: ", error);
      alert("Failed to fetch live price. Please check network or API status.");
    }
  };

  // Fetch the price when component mounts or selectedPair changes
  useEffect(() => {
    fetchPrice();
  }, [selectedPair]);

  // Handle selection change
  const handlePairChange = (pair) => {
    setSelectedPair(pair);
  };

  // Handle quantity change
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  // Handle leverage change
  const handleLeverageChange = (e) => {
    setLeverage(e.target.value);
  };

  // Handle order type change
  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  // Handle Buy action
  const handleBuy = () => {
    if (!livePrice) {
      alert("Price not available");
      return;
    }
    alert(`Placing Buy order: ${quantity} ${selectedPair} at ${livePrice}`);
  };

  // Handle Sell action
  const handleSell = () => {
    if (!livePrice) {
      alert("Price not available");
      return;
    }
    alert(`Placing Sell order: ${quantity} ${selectedPair} at ${livePrice}`);
  };

  return (
    <div className="buy-sell-container">
      <h2>Trade {selectedPair}</h2>

      <div className="pair-selection">
        <label>Select Pair:</label>
        <select onChange={(e) => handlePairChange(e.target.value)} value={selectedPair}>
          <option value="BTC/USD">BTC/USD</option>
          <option value="ETH/USD">ETH/USD</option>
          <option value="EUR/USD">EUR/USD</option>
          <option value="GBP/USD">GBP/USD</option>
          <option value="XRP/USD">XRP/USD</option>
          {/* Add more pairs as needed */}
        </select>
      </div>

      {livePrice ? (
        <div className="live-price">
          <p>Live Price: {livePrice} USD</p>
        </div>
      ) : (
        <p>Loading live price...</p>
      )}

      <div className="order-controls">
        <label>Quantity:</label>
        <input
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          min="1"
        />

        <label>Leverage:</label>
        <input
          type="range"
          value={leverage}
          onChange={handleLeverageChange}
          min="1"
          max="100"
        />
        <span>{leverage}x</span>

        <label>Order Type:</label>
        <select value={orderType} onChange={handleOrderTypeChange}>
          <option value="Market">Market</option>
          <option value="Limit">Limit</option>
        </select>
      </div>

      <div className="d-flex">
        <button className="btn btn-theme btn-success w-50" onClick={handleBuy}>Buy</button>
        <button className="btn btn-theme btn-danger w-50" onClick={handleSell}>Sell</button>
      </div>
    </div>
  );
};

export default OrderPanel;
