import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  ArrowCircleDownRounded,
  ArrowCircleUpRounded,
  HistoryRounded,
  AccountBalanceWalletOutlined,
  ReceiptLongOutlined,
  DashboardOutlined,
  MailOutlineOutlined,
  SettingsOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Sidebar.css"; // Custom CSS file to style the drawer

const Sidebar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMouseEnter = () => setDrawerOpen(true);
  const handleMouseLeave = () => setDrawerOpen(false);

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: drawerOpen ? 200 : 60,
        transition: "width 0.3s",
        "& .MuiDrawer-paper": {
          width: drawerOpen ? 200 : 60,
          overflowX: "hidden",
          transition: "width 0.3s",
        },
      }}
    >
      <List style={{ paddingTop: "115px" }}>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Accounts" />}
        </ListItem>

        <ListItem button component={Link} to="/deposit">
          <ListItemIcon>
            <ArrowCircleDownRounded />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Deposit" />}
        </ListItem>

        <ListItem button component={Link} to="/withdraw">
          <ListItemIcon>
            <ArrowCircleUpRounded style={{ transform: "rotateZ(45deg)" }} />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Withdraw" />}
        </ListItem>

        <ListItem button component={Link} to="/transaction-history">
          <ListItemIcon>
            <HistoryRounded />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Transaction History" />}
        </ListItem>

        <ListItem button component={Link} to="/crypto-wallet">
          <ListItemIcon>
            <AccountBalanceWalletOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Crypto Wallet" />}
        </ListItem>

        <ListItem button component={Link} to="/analytics">
          <ListItemIcon>
            <ReceiptLongOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Analytics" />}
        </ListItem>

        <ListItem button component={Link} to="/messages">
          <ListItemIcon>
            <MailOutlineOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Messages" />}
        </ListItem>

        <ListItem button component={Link} to="/settings">
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="Settings" />}
        </ListItem>

        <ListItem button component={Link} to="/about">
          <ListItemIcon>
            <InfoOutlined />
          </ListItemIcon>
          {drawerOpen && <ListItemText primary="About" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
